<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3">
					<div class="logo" />
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.hotelName }}</h4>
					<p>
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<br />
						<span>{{ defaults[locale].website.country }}</span>
						<br />
					</p>
				</div>
				<div class="columns column3">
					<h4>{{ $t('footerContact') }}</h4>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('footerNavigate') }}</h4>
					<p class="footer-nav">
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
					</p>
				</div>
			</div>
		</footer>
		<footer class="bottomfooter">
			<div class="row">
				<div class="columns column12 align-left">
					<hr />
					<div class="bottomfooter-row">
						<p>
							<a :href="$t('siteByUrl')" target="_blank" rel="noopener noreferrer">
								Hotel Website Design
							</a>
							by:
							<a :href="$t('siteByUrl')" target="_blank">
								<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="Site by" />
								Porter
							</a>
						</p>
						<div class="menu">
							<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
								<nuxt-link :to="item.filename">
									{{ item.header }}
								</nuxt-link>
								<span v-if="idx < bottomfooter[locale].length - 1">|</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
footer {
	background: $footer-background-color;
	color: $footer-link-color;
	padding: 100px 0 25px;
	font-size: 16px;

	h4 {
		color: #fff;
	}

	hr {
		background: #f8f3f3;
		width: 100%;
		height: 1px;
		margin: 5px auto 15px;
		border: none;
	}
}

a {
	color: $footer-link-color;
	text-decoration: none;

	svg {
		color: $cta-color;
		margin: 0 4px 0 0;
	}

	&:hover {
		color: $cta-color;
	}
}

.logo {
	background: url('~/assets/images/logo-hotel-velotel-medium-white.png') no-repeat center center;
	background-size: 170px;
	width: 170px;
	height: 50px;
}

.bottomfooter {
	background: $author-background-color;
	padding: 20px 0;
	font-size: 13px;

	p {
		margin: 0;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}

	.bottomfooter-row {
		display: flex;
		align-items: center;

		.menu {
			margin-left: auto;
		}
	}
}

@media (max-width: 900px) {
	footer .columns {
		margin: 20px auto;
		text-align: left;
	}

	footer.author .columns {
		text-align: center;
	}

	.bottomfooter {
		padding: 20px 0 120px;

		.bottomfooter-row {
			flex-flow: column wrap;

			.menu,
			p {
				width: 100%;
				margin: 7px 0;
				text-align: left;
			}
		}
	}

	footer .row:last-child .columns:last-child a {
		margin: 0;
	}
}
</style>
