<template>
	<div id="top" class="container">
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />
		</Head>

		<navigation />

		<slot />

		<footers />
		<pop-up
			v-if="page && defaults[locale].website.popupContent"
			:image-webp="defaults[locale].website.popupImageWebp"
			:image="defaults[locale].website.popupImage"
			:content="defaults[locale].website.popupContent"
		/>
		<cookie-consent
			v-if="page && defaults[locale].cookieConsent.content"
			:content="defaults[locale].cookieConsent.content"
			:position="defaults[locale].cookieConsent.position"
		/>
		<to-top />
		<book-button type="fixedmobile" class="button button-cta reserve-now right">
			<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
			<span>{{ $t('bookNow') }}</span>
		</book-button>

		<conversion-triggers :notifications="page && page.conversiontriggers" />
	</div>
</template>

<script setup>
import { useGtm } from '@gtm-support/vue-gtm';

const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
await fetchPage();

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.hotelName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.hotelName || '',
});

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'Hotel',
				'@id': `${config.public.siteUrl}#hotel`,
				image: website.structuredImage,
				url: config.public.siteUrl,
				name: website.hotelName,
				logo: `${config.public.siteUrl}/images/logo.png`,
				telephone: website.phone,
				email: website.email,
				address: {
					'@type': 'PostalAddress',
					streetAddress: `${website.street} ${website.number}, ${website.city}`,
					postalCode: website.postalCode,
				},
			}),
		},
	],
});

onMounted(() => {
	window.clockPmsWbeInit &&
		window.clockPmsWbeInit({
			wbeBaseUrl: 'https://sky-eu1.clock-software.com/spa/pms-wbe/#/hotel/11152',
			defaultMode: 'fullscreen',
			roundedCorners: false,
			language: locale.value,
		});

	const gtm = useGtm();
	window.clockPmsWbePageViewCallback = function (params) {
		if (params.pageName === 'completed') {
			// Recommended in the docs to prevent multiple ecommerce events on a page from affecting each other
			gtm.push({ ecommerce: null });

			gtm.push({
				event: 'purchase',
				ecommerce: {
					value: (params.totalPriceCents / 100).toString(),
					transaction_id: params.bookingNumbers.join(''),
					currency: params.totalPriceCurrency,
					items: [
						{
							item_name: 'Accommodation',
						},
					],
				},
			});
		}
	};
});
</script>
